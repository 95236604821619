import { BILL_DATA, BILL_GEN_SUMMARY, BILL_LIST, PENDING_BILL_LIST } from '../constant/constant';

export const billData = function (state = [], action) {
  switch (action.type) {
    case BILL_DATA:
      return action.payload;
    // case CLEAR_SINGLE_BILTY_DATA:
    //   return state
    default: {
      return state;
    }
  }
};
export const billList = function (state = [], action) {
  switch (action.type) {
    case BILL_LIST:
      return action.payload;
    default: {
      return state;
    }
  }
};

export const pendingBillSummary = function (state = [], action) {
  switch (action.type) {
    case PENDING_BILL_LIST:
      return action.payload;
    case BILL_GEN_SUMMARY: {
      return state.filter(({ _id }) => _id !== action.payload);
    }
    default: {
      return state;
    }
  }
};
